// // import React from 'react';

// // const ProductModal = ({ isOpen, onClose, product }) => {
// //   if (!isOpen || !product) return null;

// //   return (
// //     <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
// //       <div className='absolute inset-0 bg-black opacity-50'></div>
// //       <div className='relative bg-white p-6 max-w-[90vw] max-h-[90vh] mx-auto rounded-lg z-50 overflow-y-auto'>
// //         <button
// //           className='absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-900'
// //           onClick={onClose}
// //         >
// //           Close
// //         </button>
// //         <h2 className='text-2xl font-bold mb-4'>{product.name}</h2>
// //         <div className='flex justify-center mb-4'>
// //           <img src={product.src} alt={product.name} className='w-64 h-64 object-cover' />
// //         </div>
// //         <p className='mb-4'>{product.description || "No description available."}</p>
// //         <div className='flex justify-end'>
// //           <button className='btn btn-primary mr-2 hover:bg-blue-700'>
// //             Enquire Now
// //           </button>
// //           <button className='btn btn-secondary hover:bg-gray-700'>
// //             Call Now
// //           </button>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ProductModal;


// import React from 'react';

// const ProductModal = ({ isOpen, onClose, product, callNumber, whatsappNumber }) => {
//   if (!isOpen || !product) return null;

//   const handleEnquireNow = () => {
//     window.open(`https://wa.me/${whatsappNumber}`, '_blank');
//   };

//   const handleCallNow = () => {
//     window.open(`tel:${callNumber}`, '_blank');
//   };

//   return (
//     <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
//       <div className='absolute inset-0 bg-black opacity-50'></div>
//       <div className='relative bg-white p-6 max-w-[90vw] max-h-[90vh] mx-auto rounded-lg z-50 overflow-y-auto'>
//         <button
//           className='absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-900'
//           onClick={onClose}
//         >
//           Close
//         </button>
//         <h2 className='text-2xl font-bold mb-4'>{product.name}</h2>
//         <div className='flex justify-center mb-4'>
//           <img src={product.src} alt={product.name} className='w-64 h-64 object-cover' />
//         </div>
//         <p className='mb-4'>{product.description || "No description available."}</p>
//         <div className='flex justify-end'>
//           <button className='btn btn-primary mr-2 hover:bg-blue-700' onClick={handleEnquireNow}>
//             Enquire Now
//           </button>
//           <button className='btn btn-secondary hover:bg-gray-700' onClick={handleCallNow}>
//             Call Now
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductModal;

import React from 'react';
const callNumber=9999554203 ;
const whatsappNumber=999955420 ;

const ProductModal = ({ isOpen, onClose, product}) => {
  if (!isOpen || !product) return null;


  const handleEnquireNow = () => {
    // Replace 'whatsappNumber' with the actual number from product or props
    const whatsappNumber = '9990764187 '; // Example number
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
  };

  // Function to handle Call Now click
  const handleCallNow = () => {
    // Replace 'callNumber' with the actual number from product or props
    const callNumber = '9990764187 '; // Example number
    window.open(`tel:${callNumber}`, '_blank');
  };

  
  return (
    <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
      <div className='absolute inset-0 bg-black opacity-50'></div>
      <div className='relative bg-white p-6 max-w-[90vw] max-h-[90vh] mx-auto rounded-lg z-50 overflow-y-auto'>
        <button
          className='absolute top-0 right-0 m-1 text-gray-600 hover:text-gray-900'
          onClick={onClose}
        >
         <span style={{fontSize:"smaller"}}> <strong>&#x2715;</strong> </span> 
        </button>
        <h2 className='text-2xl font-bold mb-4'>{product.name}</h2>
        <div className='flex justify-center mb-4'>
          <img src={product.src} alt={product.name} className='w-64 h-64 object-cover' />
        </div>
        <div className='mb-4'>
            <p> <span  style={{ fontStyle:"bold", color:"black"}} >{product.kg}</span> <span style={{ fontStyle:"bold", color:"gray"}} > Kg</span><br /> <span style={{ fontStyle:"bold", color:"black", fontSize:"smaller"}} > Get Latest Price

Minimum Order Quantity: <span style={{fontSize:"larger", fontStyle:"bolder"}}>1000 Kg</span> </span> </p>
          <table className='w-full'>
            <tbody>
              {Object.entries(product.details).map(([key, value]) => (
                <tr key={key}>
                  <td className='py-2 px-4 font-semibold text-gray-800'>{key}</td>
                  <td className='py-2 px-4 text-gray-600'>{value}</td>
                </tr>
              ))}
              <tr>
               <td colSpan={2}> &nbsp; </td>
              </tr>
              <tr key={null}>
                <td colSpan={2} style={{color:"black"}} >{product.p1} </td>
              </tr>
              <tr key={null}>
                <td colSpan={2}> &nbsp; </td>
              </tr>
             
              <tr key={null}>
                <td colSpan={2} style={{color:"black"}} >{product.p2}</td>
              </tr>
              <tr key={null}>
                <td colSpan={2}> &nbsp; </td>
              </tr>
             
              <tr key={null}>
                <td colSpan={2} style={{color:"black"}} >
                
                <h1> <strong>{product.p3}</strong></h1>

                    <ul>
  <li> {product.p4}</li>
  <li> {product.p5}</li>
  <li> {product.p6}</li>
</ul>
                    
                      </td>
              </tr>
            </tbody>
          </table>
         
        </div>
        <div className='flex justify-end'>
          <button className='btn btn-primary mr-2 hover:bg-blue-700' onClick={handleEnquireNow}>
            Enquire Now
          </button>
          <button className='btn btn-secondary hover:bg-gray-700' onClick={handleCallNow}>
            Call Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
