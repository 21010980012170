import React from 'react'
import './WhatsappButton.css';
const WhtasappButton = () => {
  return (
    <a
    href="https://wa.me/9999554203" // Replace with your WhatsApp number
    className="whatsapp-button"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src="https://img.freepik.com/premium-vector/whatsapp-icon-concept_23-2147897840.jpg" alt="WhatsApp Icon" className="whatsapp-icon" />
    Let's get Connected &#8594;
  </a>
  )
}

export default WhtasappButton