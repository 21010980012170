import React from 'react'

const AboutHeader = () => {
  return (
    <div className=' bg-black max-w-full font-bold text-[#fff]' style={{marginTop:"1em", width:"100%", marginBottom:"-3em", textAlign:"center"} }>
        <div className="flex  flex-col " style={{margin:"auto", width:"100%", alignItems:"center", padding:"5px"}}>
           
            <h1 style={{fontSize:"24px", margin:"25px 0px",textAlign:"center"}}>Company Introduction</h1> 
            <h3  style={{ margin:"25px 0px"}}>Mehar Plastics Industries is leading manufacturer and wholesaler of Nylon 6
      Granules, Nylon 66 Granules, etc. with head office in New Delhi. Our product is manufactured with utmost quality check and standards so that product supplied to our esteemed clients are of best quality. We have multiple plants with latest driven technology and equipments like twin screw extruder and latest testing labs in our premises. We specially make granules for compounding industry as per their requirement.
      We also feed them with raw material like best quality scrap/rejected plant waste.
      Mr Fateh Singh B.com, M.com In International Business and PGD in Polymer Science, Plastic Technology.</h3>
           
           
           
        </div>
    </div>
  )
}

export default AboutHeader