import React from 'react';
// import contact data
import { contactData } from '../data';
// import motion
import { motion } from 'framer-motion';
// import variants
import { fadeIn } from '../variants';
import Form from './Form';

const Contact = () => {
  // destructure contact data
  const { title, info, form } = contactData;
  return (
    <section className='section ' id='contact' >
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-y-16 text-[#333]'>
          {/* text */}
          <motion.div
            variants={fadeIn('right')}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.4 }}
            className='flex-1'
          >
            {/* title */}
            <h2 className='h2 max-w-[490px] mt-20'>{title}</h2>
            {/* info items */}
            <div className='flex flex-col xl:flex-row gap-x-5 gap-y-16 xl:gap-y-0'>
              {info.map((item, index) => {
                // destructure item
                const { title, subtitle, address, phone, email, link } = item;
                return (
                  // item
                  <div key={index}>
                    {/* title */}
                    <div className='font-primary uppercase font-medium text-xl mb-3'>
                      {title}
                    </div>
                    {/* subtitle */}
                    <div className='mb-6 text-[#333] leading-[187%] tracking-[0.02em]'>
                      {subtitle}
                    </div>
                    {/* address, phone & email */}
                    <div className='flex flex-col gap-y-3 mb-8'>
                      {/* address */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{address.icon}</div>
                        <div className='font-medium'>{address.name}</div>
                      </div>
                      {/* phone */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{phone.icon}</div>
                        <div className='font-medium'>{phone.number}</div>
                      </div>
                      {/* email */}
                      <div className='flex items-center gap-[10px]'>
                        <div>{email.icon}</div>
                        <div className='font-medium'>{email.address}</div>
                      </div>
                    </div>
                    {/* link */}
                    <a
                      className='font-medium border-b border-dark pb-[5px]'
                      href='https://www.google.com/maps?q=28.7834348,77.0508554&z=17&hl=en' target="_blank"
                      rel="noreferrer"
                     
                    >
                      {link}
                    </a>
                  </div>
                );
              })}
            </div>
          </motion.div>
          {/* form */}
          <motion.div
            variants={fadeIn('left')}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.4 }}
            className='flex-1 xl:pl-[40px] flex justify-center items-center '
          >
            {/* <Form/> */}
            <div style={{display:"flex", flexDirection:"column", width:"100%", height:"100%"}}> 
            <div style={{display:"flex", justifyContent:"center"}}>
            <address> Mehar Polymers</address>
            </div>
            <div style={{display:"flex", justifyContent:"center", marginTop:"10px"}}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3496.8595365484434!2d77.04828617544736!3d28.78344907721154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQ3JzAwLjQiTiA3N8KwMDMnMDMuMSJF!5e0!3m2!1sen!2sin!4v1719143918844!5m2!1sen!2sin" width="500" height="500"  allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            
            </div>
           
            </div>
           

            
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
