// import React, { useState } from 'react';
// // import data
// import { galleryData } from '../data';
// // import photo album & lightbox
// import PhotoAlbum from 'react-photo-album';
// import Lightbox from 'yet-another-react-lightbox';
// import 'yet-another-react-lightbox/styles.css';
// // import motion
// import { motion } from 'framer-motion';
// // import variants
// import { fadeIn } from '../variants';

// const slides = galleryData.images.map(({ original, width, height }) => ({
//   src: original,
//   width,
//   height,
// }));

// const GallerySection = () => {
//   const [index, setIndex] = useState(-1);
//   // destructure gallery data
//   const { title, btnText, btnIcon, images } = galleryData;
//   return (
//     <section className='bg-[#F9F9F9] section relative  lg:mt-0' id='gallery'>
//       <div className='container mx-auto'>
//         <motion.h2
//           variants={fadeIn('up')}
//           initial='hidden'
//           whileInView={'show'}
//           viewport={{ once: false, amount: 0.6 }}
//           className='h2 max-w-[370px] lg:mb-20'
//         >
//           {title}
//         </motion.h2>
//       </div>
//       {/* photo album */}
//       <motion.div
//         variants={fadeIn('up')}
//         initial='hidden'
//         whileInView={'show'}
//         viewport={{ once: false, amount: 0.2 }}
//         className='mb-8 lg:mb-20'
//       >
//         <PhotoAlbum
//           layout='rows'
//           photos={images}
//           onClick={(event, photo, index) => setIndex(index)}
//         />
//         <Lightbox
//           slides={slides}
//           styles={{ container: { backgroundColor: 'rgba(0,0,0,.9)' } }}
//           open={index >= 0}
//           index={index}
//           close={() => setIndex(-1)}
//         />
//       </motion.div>
//       {/* btn */}
//       <motion.div
//         variants={fadeIn('up')}
//         initial='hidden'
//         whileInView={'show'}
//         viewport={{ once: false, amount: 0.2 }}
//         className='flex justify-center'
//       >
//         {/* <button className='btn btn-lg btn-dark'>
//           {btnText}
//           <div className='text-xl'>{btnIcon}</div>
//         </button> */}
//       </motion.div>
//     </section>
//   );
// };

// export default GallerySection;


import React, { useState } from 'react';
import { galleryData } from '../data';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import ProductModal from './ProductModal'; // Assuming you have a modal component

const GallerySection = () => {
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { title, images } = galleryData;

  const handleViewMoreClick = (index) => {
    setClickedIndex(index);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClickedIndex(-1);
  };

  return (
    <section className='bg-[#F9F9F9] section relative lg:mt-0' id='gallery'>
      <div className='container mx-auto'>
        <motion.h2
          variants={fadeIn('up')}
          initial='hidden'
          whileInView={'show'}
          viewport={{ once: false, amount: 0.6 }}
          className='h2 max-w-[370px] lg:mb-20'
        >
          {title}
        </motion.h2>
      </div>

      <motion.div
        variants={fadeIn('up')}
        initial='hidden'
        whileInView={'show'}
        viewport={{ once: false, amount: 0.2 }}
        className='mb-8 lg:mb-20'
      >
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          {images.map((photo, index) => (
            <div key={index} className='relative'>
              <img
                src={photo.src}
                alt={photo.alt}
                className='w-full h-auto max-h-60 sm:max-h-48 object-cover cursor-pointer rounded-lg'
                onClick={() => handleViewMoreClick(index)}
              />
              <button
              
                className='absolute inset-0 flex items-center justify-center bg-white text-gray-700 px-2 py-1 rounded-md shadow-sm border border-gray-200 hover:bg-gray-100 hover:text-gray-900'
                style={{
                  inset: 'auto', // Customize inset properties here
                  left: '50%',
                  bottom: '2%',
                  transform: 'translate(-50%, -50%)',  // Center button
                }}
                onClick={() => handleViewMoreClick(index)}
              >
                View More
              </button>
            </div>
          ))}
        </div>
      </motion.div>

      <ProductModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        product={images[clickedIndex]}
      />
    </section>
  );
};

export default GallerySection;

