// import images
import Logo from "../src/img/header/Logo PNG.png";
import GalleryImg1 from "../src/img/gallery/p1.webp";
import GalleryImg2 from "../src/img/gallery/p2.webp";
import GalleryImg3 from "../src/img/gallery/p3.webp";
import GalleryImg4 from "../src/img/gallery/p5.webp";
import GalleryImg5 from "../src/img/gallery/5.webp";
import GalleryImg6 from "../src/img/gallery/p7.webp";
import GalleryImg7 from "../src/img/gallery/p11.jpg";

import auto from "../src/img/about/image.png";
import QuoteImg from "../src/img/testimonial/quote.svg";
// import icons
import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io";

export const headerData = {
  logo: Logo,
};

export const navData = {
  items: [
    { href: '/', name: "Home" },
    { href: "#gallery", name: "Products" },
    { href: "#about", name: "About" },
    { href: "#contact", name: "Contact" },
    // { href: '/', name: 'Interview' },
    // { href: '/', name: 'Articles' },
  ],
};

export const socialData = [
  { href: "https://www.facebook.com/profile.php?id=100092550017079" , target:"blank", icon: <GrFacebookOption /> },
  { href: "https://www.instagram.com/the_spark_exhibition/", icon: <IoLogoInstagram /> },
  // { href: "/", icon: <IoLogoPinterest /> },
  // { href: "/", icon: <IoLogoTwitter /> },
  // { href: "/", icon: <IoLogoYoutube /> },
];

export const heroData = {
  title: "Mehar Polymers",
  subtitle: "Where Quality, Customers, and Innovation are Given Priority above Rest",
  btnText: "What we Provide",
  btnIcon: <IoMdArrowForward />,
};

export const aboutData = {
  // title: "My Story:",
  title1:
    "Automobiles",
  subtitle1:
    `To meet today's ever-growing demand for low carbon emission, lightweight solutions, metal replacement, we offer the best solution to meet challenging demand to meet new regulations, changing consumer trends and increased environmental regulations.

    We provide innovative solutions for various industries and applications, ensuring that our products meet the highest standards of quality and environmental responsibility.
    
    Interior + Exteriors + Under the Hood`,
  btnText: "Know more",
  auto:{
    src: auto,
    original: auto,
    width: 465,
    height: 540,
  },
  
  

  // title: "My Story:",
  title2:
    "Electrical & Electronic",
  subtitle2:
    `In the rapidly evolving landscape of today’s world, digitization is the defining trend. Mechanical devices are seamlessly controlled by their digital counterparts, revolutionizing industries such as autonomous driving, robotics, and smart machinery. While exceptional mechanical and electrical prowess is essential, the need for sustainable fire safety solutions is more critical than ever.

    We understand the intricate balance between technological innovation and safety. Our commitment to this sector is rooted in delivering cutting-edge electrical and electronic solutions that not only excel in mechanical and electrical strength but also prioritize sustainable fire safety measures.
    
    In an era where technology and automation are the driving forces of progress, we aim to be at the forefront, ensuring that the transition to digital supremacy remains safe and secure. Our dedication to excellence and safety underscores our mission in the field of Electrical & Electronic solutions
    Lighting + Switchgear + Smartphone.`,
  
    title3:
    "Dismantle & Storage",
  subtitle3:
    "Once the trade show concludes triumphantly, our team stands prepared to dismantle your stall. Additionally, we offer the option to store the expo booth material in our warehouse for your future exhibition, at a nominal price.",

    title4:
    "Industrial & Consumer Goods",
  subtitle4:
    `In the fabric of our modern lives, industrial and consumer goods play an integral role, setting the standards for excellence in aesthetics and durability. Household products, in particular, require multi-functional features while maintaining the highest safety standards. Simultaneously, they must possess an attractive design, lightweight construction, and unwavering reliability.

    We understand the intricate demands of these industries, where form meets function, and safety is paramount. Our commitment to this sector is centered on delivering products that not only exude exceptional aesthetics and durability but also encompass multifunctionality, ensuring utmost safety in the home environment
    
    Furniture + Agriculture + Industrial Machine + Appliance`,

    title5:
    "Plastic Radial Ball Bearings",
  subtitle5:
    ` Bearings is a plastic radial ball bearings manufacturer utilizing engineering polymers
     Bearings manufactured with these materials are suitable for a wide range of applications including Food, Medical, Pool and Spa, Marine, Semi-Conductor
     Bearings produced with these materials can run in and around liquids or can run dry without the worry of lubrication.`,

    title6:
    "Optimize Your Feedback",
  subtitle6:
    "We highly value client feedback as it plays a crucial role in enhancing our outcomes. By optimizing your sentiments, we strive to develop a compelling stall concept that truly stands out.",

    

  btnIcon: <IoMdArrowForward />,
};

// export const galleryData = {
//   title: " PRODUCTS:",
//   btnText: "View all",
//   btnIcon: <IoMdArrowForward />,
  
//   images: [
//     {
//       src: GalleryImg1,
//       original: GalleryImg1,
//       description: 'Description for Product 2. Consectetur adipiscing elit.',
//       width: 465,
//       height: 412,
//     },
//     {
//       src: GalleryImg2,
//       original: GalleryImg2,
//       description: 'Description ',
//       width: 465,
//       height: 540,
//     },
//     {
//       src: GalleryImg3,
//       original: GalleryImg3,
//       width: 465,
//       height: 412,
//     },
//     {
//       src: GalleryImg4,
//       original: GalleryImg4,
//       width: 465,
//       height: 540,
//     },
    

//   ],
// };

export const galleryData = {
  title: " PRODUCTS:",
  btnText: "View all",
  btnIcon: '<IoMdArrowForward />',
  
  images: [
    {
      name: "Engineering Nylon Black Plain 6, 66",
      src: GalleryImg1,
      original: GalleryImg1,
      kg:"₹ 110/",

      details: {
        Condition: 'Recycled',
        Material: 'Nylon',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
        Brand: 'Semi',
        'Packaging Type': '25kg',
        Thickness: '10 mm',
        Density: '0.93%',
        'Hardness And Rigidity': 'Depends on your Requirement',
        'Melting Point': '250'
      },
      p1:"We are into Manufacturing of Nylon glass filled grinding From  New Delhi Bawana sector 3.",
      p2:"Black 66 15 % Glass Filled PA6 Nylon Granules for Auto Engineer.",
      p3:"Additional Information:",
      p4:"Production Capacity: 60T",
      p5:"Delivery Time: On Time",
      p6:"Packaging Details: 25 kg",
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      name:"Engineering Black 66 15 % Glass Filled Pa6 Nylon Granules For Auto Engineer",
      kg:"₹ 95/ ",
      details: {
       
        Material: 'Nylon Glass 66',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
       
        Thickness: '	4mm',
        Density: '0.93%',
        'Hardness And Rigidity': '	PA6',
       
      },
      p1:"Black 66 15 % Glass Filled PA6 Nylon Granules for Auto Engineer",
     
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      name:"Engineering Nylon Plain Black Granules For Engineering Plastics",
      kg:"₹ 105/",
      details: {
        Condition: 'Recycled',
        Material: 'Nylon',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
        Brand: 'Semi',
        'Packaging Type': '25kg',
        Thickness: '8 mm',

        'Hardness And Rigidity': '15%',
       
      },
      p1:"Engineering Nylon Plain Black Granules For Engineering Plastics",
     
      width: 465,
      height: 412,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      name:"Engerineering Nylon 66 30% Glass Filled granules",
      kg:"₹ 90/",
      details: {
        
        Material: 'Nylon 6630 %. granules',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
       
      },
      p1:"Engerineering Nylon 66 30% Glass Filled granules",
     
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      name:"Nylon 6&66 (PA6 and PA66 ) 15% & 30% glass filled granules",
      kg:"₹ 100/",
      details: {
        
        Material: 'Nylon Glass',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
        "Grade Standard":"	30%",
        "Granule Grade":"6",
       
      },
      p1:"Nylon 6&66 (PA6 and PA66 ) 15% & 30% glass filled granules",
     
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      name:"9Mm Pa6 Nylon Granules For Engineering Plastics Pakaging Size 25 Kg",
      kg:"₹ 150/",
      details: {
        
        Material: 'Nylon',
        'Usage/Application': 'Moulding',
        Color: 'Black',
        Type: 'Granules',
        'Packaging Size': '25 kg',
        Thickness:"9mm",
        "Grade Standard":"66%",
        "Granule Grade":"6 Granule",

       
      },
      p1:"9Mm Pa6 Nylon Granules For Engineering Plastics Pakaging Size 25 Kg",
     
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      name:"Gray Nylon 6 (PA6) Grey Plain , FR and Glass Filled MCB Grade Plastic Dana, For Engineering Plastics, Packaging Size: 10KG",
      kg:"₹ 125/ ",
      details: {
        
        Material: 'Nylon Gray MCB Grade',
        'Usage/Application': 'Engineering Plastics',
        Color: 'Gray',
        Type: 'Nylon 6 (PA6) Grey Plain , FR and Glass Filled M',
        'Packaging Size': '10 kg',
        

       
      },
      p1:"Polycarbonate Granules, PP Granules, PBT Granules, ",
     
      width: 465,
      height: 540,
    },
  ],
};


export const interviewData = {
  title:
    "“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”",
  btnText: "Watch it now",
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      `Mehar Plastics Industries is leading manufacturer and wholesaler of Nylon 6
      Granules, Nylon 66 Granules, etc. with head office in New Delhi. Our product is manufactured with utmost quality check and standards so that product supplied to our esteemed clients are of best quality. We have multiple plants with latest driven technology and equipments like twin screw extruder and latest testing labs in our premises. We specially make granules for compounding industry as per their requirement.
      We also feed them with raw material like best quality scrap/rejected plant waste.
      Mr Fateh Singh B.com, M.com In International Business and PGD in Polymer Science, Plastic Technology.`,
    name: "Mehar Polymers",
    occupation: "@Team",
  },
  // {
  //   quoteImg: QuoteImg,
  //   message:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam culpa asperiores officia natus dignissimos autem possimus molestias aperiam?",
  //   name: "Douglas Hane",
  //   occupation: "Tattoo Artist",
  // },
];

export const contactData = {
  title: "Get in touch with Us:",
  info: [
    {
      title: "Mehar Polymers",
      subtitle:
        "Feel free to reach out us",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "DSIIDC Industrial Area,c-41 Sector 3, Bawana, Delhi, 110039",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: `9999554203, +99907 64187`,
      },
      email: {
        icon: <FaEnvelope />,
        address: "meharplasticsindustries@gmail.com",
      },
      link: "Get location",
    },
    
  ],
  form: {
    name: "Write your name here",
    email: "Write your email address",
    message: "Write your messages here",
    btnText: "Send it",
  },
};

export const footerData = {
  about: {
    title: "About Mehar Polymers",
    subtitle:
      "We'r more than happy to serve please feel free to get connected",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "DSIIDC Industrial Area,c-41 Sector 3 , Bawana, Delhi, 110039",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: ` 9999554203,+99907 64187`,
    },
    email: {
      icon: <FaEnvelope />,
      address: "meharplasticsindustries@gmail.com",
    },
  },
  links: {
    title: "Useful links",
    items: [
      { href: "#about", name: "About us" },
      { href: "#gallery", name: "Gallery" },
      { href: "#contact", name: "Contact us" },
    ],
  },
  
  newsletter: {
    title: "Newsletter",
    subtitle:
      "We Will Get in Touch Soon 😊 😊",
    form: {
      placeholder: "Your email address",
      icon: <FiSend />,
    },
  },
};
